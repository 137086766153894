import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoginDto } from '../web-api-client';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  username: string;
  password: string;

  constructor(private authService: AuthService, private router: Router) {}

  onSubmit() {
    this.authService.login(this.username, this.password).subscribe((loginDto: LoginDto) => {
      localStorage.setItem('jwt', loginDto.token);
      this.router.navigate(['/']);
    }, (error: any) => {
      console.error('Login failed', error);
    });
  }
}
