import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicEventClient, CreatePublicEventCommand, PublicEventVm } from 'src/app/web-api-client';

@Injectable({
    providedIn: 'root'
})
export class PublicEventService {
    
    constructor(private publicEventClient: PublicEventClient) {}

    getAllPublicEvents() : Observable<PublicEventVm> {
        return this.publicEventClient.getPublicEvents();
    }

}