import { Component, OnInit } from '@angular/core';
import { PublicEventService } from 'src/services/publicEvent.service';
import { PublicEventVm } from '../web-api-client';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrl: './upcoming-events.component.css'
})
export class UpcomingEventsComponent implements OnInit {
  public events: PublicEventVm | undefined; 
  
  constructor(private publicEventService: PublicEventService){}

  ngOnInit(): void {
    this.loadPublicEvents();
  }

  loadPublicEvents(): void {
    
    this.publicEventService.getAllPublicEvents().subscribe({
      next: (data) => {
        this.events = data; // Assign the data to the events variable
        console.log(this.events); // Optionally log the events to the console
      },
      error: (err) => {
        console.error('Error fetching public events:', err); // Handle error case
      }
    });
  }

}
