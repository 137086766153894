<h1>Todo</h1>

<p>This is a complex todo list component.</p>

<p *ngIf="!lists"><em>Loading...</em></p>

<div *ngIf="lists">

  <div class="row">
    <div class="col-sm-4">
      <div class="clearfix">
        <h2 class="float-start">Lists</h2>
        <button class="btn btn-default float-end" title="New List..."
          (click)="showNewListModal(newListModalTemplate)">
          <i class="bi bi-journal-plus"></i>
        </button>
      </div>
      <ul class="list-group">
        <li *ngFor="let list of lists; index as i" class="list-group-item"
          [ngClass]="{ 'active': selectedList == list }" (click)="selectedList = list">
          <div class="clearfix">
            <div class="float-start">
              {{ list.title }}
            </div>
            <div class="float-end text-right">
              <span class="badge badge-light">{{ remainingItems(list) }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-sm-8" *ngIf="selectedList">
      <div class="clearfix">
        <h2 class="float-start">{{ selectedList.title }}</h2>
        <button id="listOptions" class="btn btn-default float-end" title="List Options..."
          (click)="showListOptionsModal(listOptionsModalTemplate)">
          <i class="bi bi-three-dots-vertical"></i>
        </button>
      </div>
      <ul id="todo-items" class="list-group mb-2">
        <li class="list-group-item" *ngFor="let item of selectedList.items; index as i">
          <div class="d-flex">
            <div class="todo-item-checkbox">
              <input type="checkbox" [(ngModel)]="item.done" (change)="updateItem(item)" />
            </div>
            <div class="flex-fill">
              <input id="{{ 'itemTitle' + i }}" *ngIf="item == selectedItem" type="text"
                class="form-control item-input-control" [(ngModel)]="item.title" (keyup.enter)="updateItem(item, true)"
                (blur)="updateItem(item)" autofocus="autofocus" maxlength="200" />
              <div class="todo-item-title" *ngIf="item != selectedItem" [ngClass]="{ 'done-todo': item.done }"
                (click)="editItem(item, 'itemTitle' + i)" class="form-control item-input-control">
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div class="todo-item-commands">
              <button *ngIf="item.id != 0" (click)="showItemDetailsModal(itemDetailsModalTemplate, item)"
                class="btn btn-default btn-xs" role="button">
                <i class="bi bi-three-dots-vertical"></i>
              </button>
            </div>
          </div>
        </li>
        <li class="list-group-item" (click)="addItem()">
          <button class="btn btn-default">Add Item...</button>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="debug">
  <pre>{{ lists | json }}</pre>
</div>

<ng-template #listOptionsModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">List Options</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="listOptionsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="inputListTitle">Title</label>
      <input type="text" class="form-control" id="inputListTitle" placeholder="List name..."
        [(ngModel)]="listOptionsEditor.title" (keyup.enter)="updateListOptions()" maxlength="200" />
    </div>
    <div *ngIf="debug">
      <pre>{{ listOptionsEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="clearfix">
      <div class="float-start">
        <button class="btn btn-default text-danger" (click)="confirmDeleteList(deleteListModalTemplate)">Delete</button>
      </div>
      <div class="float-end">
        <button class="btn btn-default" (click)="listOptionsModalRef.hide()">Cancel</button>
        <button class="btn btn-primary" (click)="updateListOptions()">Update</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #itemDetailsModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Item Details</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="itemDetailsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form">
      <div class="form-group">
        <label for="list">List</label>
        <select class="form-control" [(ngModel)]="itemDetailsEditor.listId">
          <option [ngValue]="list.id" *ngFor="let list of lists">{{ list.title }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="priority">Priority</label>
        <select class="form-control" [(ngModel)]="itemDetailsEditor.priority">
          <option [ngValue]="level.id" *ngFor="let level of priorityLevels">{{ level.title }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="note">Note</label>
        <textarea id="note" class="form-control" rows="3" [(ngModel)]="itemDetailsEditor.note"></textarea>
      </div>
    </div>
    <div *ngIf="debug">
      <pre>{{ itemDetailsEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="clearfix">
      <div class="float-start">
        <button class="btn btn-default text-danger" (click)="deleteItem(selectedItem)">Delete</button>
      </div>
      <div class="float-end">
        <button class="btn btn-default" (click)="itemDetailsModalRef.hide()">Cancel</button>
        <button class="btn btn-primary" (click)="updateItemDetails()">Update</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteListModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Delete "{{ selectedList?.title }}"?</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="deleteListModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>All items will be permanently deleted. </p>
    <div *ngIf="debug">
      <pre>{{ selectedList | json }}</pre>
    </div>
  </div>
  <div class="modal-footer clearfix">
    <div class="float-end">
      <button type="button" class="btn btn-default" (click)="deleteListModalRef.hide()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="deleteListConfirmed()">Delete</button>
    </div>
  </div>
</ng-template>

<ng-template #newListModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">New List</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="newListCancelled()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="title">Title</label>
      <input type="text" class="form-control" id="title" placeholder="List title..." [(ngModel)]="newListEditor.title"
        [ngClass]="{ 'is-invalid': newListEditor.error }" (keyup.enter)="addList()" maxlength="200" />
      <div *ngIf="newListEditor.error" class="invalid-feedback">
        {{ newListEditor.error }}
      </div>
    </div>
    <div *ngIf="debug">
      <pre>{{ newListEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer clearfix">
    <div class="float-end">
      <button class="btn btn-default" (click)="newListCancelled()">Cancel</button>
      <button class="btn btn-primary" (click)="addList()">Create</button>
    </div>
  </div>
</ng-template>
